.gradient-text {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #F6C1C9, #A1CEEB, #0B1956);
}


.gradient-line {
  background-image: linear-gradient(to bottom right, #ADD0E3, #FEE5E0);
  height: 4px;
  width: 118px;
  border-radius: 4px;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(90deg, #ADD0E3, #F6C1C9, #FEE5E0); /* Adjust gradient colors */
  border-radius: inherit; /* Match border radius */
  padding: 1px; /* Thickness of the border */
  mask: linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude; /* For transparent inner area */
}

.button_hover:hover{
  background-color: #FFFFFF;
  color: #0B1956;
  border: 2px solid;
  border-image: linear-gradient(114.3deg, #ADD0E3 22.24%, #F6C1C9 63.47%, #FEE5E0 95.88%) 1;  
  border-radius: 8px;
  transition: all 0.2s ease-in ;
}

.button_hover:hover &::before{
  content: '';
  position: absolute;
  top: -5;
  left: -5;
  right: -5;
  bottom: -5;
  border: 2px solid;
  border-image: linear-gradient(114.3deg, #ADD0E3, #F6C1C9, #FEE5E0); /* Adjust gradient colors */
  border-radius: 8px; /* Match border radius */
  padding: 2px; /* Thickness of the border */
}