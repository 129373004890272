@tailwind base;
@tailwind components;
@tailwind utilities;

/* --- FONTS --- */

/* Gordita */
@font-face {
  font-family: "Gordita";
  src: url("./assets/fonts/Gordita/Gordita-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: "Gordita";
    src: url("./assets/fonts/Gordita/Gordita-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  

@font-face {
  font-family: "Gordita";
  src: url("./assets/fonts/Gordita/Gordita-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* PolySans */

@font-face {
    font-family: "PolySans";
    src: url("./assets/fonts/PolySans/PolySans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/PolySans/PolySans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/PolySans/PolySans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Gordita", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PolySans", sans-serif;
}
